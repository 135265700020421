.name {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin: 24px auto;
  display: block;
  background-size: cover;
  background-position: center;
}

.change {
  margin: 0 auto;
  display: block;
  text-decoration: underline;
}

.label {
  width: 300px;
  margin: 12px auto 6px;
  font-weight: bold;
}

.input {
  width: 300px;
  margin: 0 auto;
  display: block;
  border: 1px solid #a2c198;
}

.button {
  margin: 0 auto;
  background-color: #a2c198;
  border-radius: 18px;
  color: white;
  display: block;
  font-size: 16px;
  margin-top: 24px;
  height: 36px;
  padding: 0 24px;
}

.wall {
  width: 300px;
  height: 1px;
  background-color: #a2c198;
  margin-top: 35px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.placeholder {
  width: 300px;
  border: 1px dashed #a2c198;
  height: 160px;
  margin: 0 auto;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}
