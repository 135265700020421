.tabs {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: white;
  padding-bottom: 48px;
}

.filters {
  position: fixed;
  top: 60px;
  background-color: white;
  width: 100vw;
}

.table {
  margin-bottom: 84px;
  font-size: 12px;
  max-width: 1000px;
  display: block;
  margin-top: 212px;

  &.withdrawals {
    margin-top: 104px;
    td,
    th {
      width: 20%;
    }

    tr:first-child {
      top: 60px;
    }
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 9%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr {
    display: flex;
    width: 100vw;
  }

  tr:first-child {
    position: fixed;
    top: 168px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4caf50;
    color: white;
  }
}
