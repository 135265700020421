.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99;
  left: 0;

  .modal {
    width: 80%;
    background-color: white;
    border: 4px solid rgb(162, 193, 152);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 24px;

    .title {
      font-size: 32px;
      font-weight: bold;
    }

    p {
      font-size: 16px;

      a {
        text-decoration: underline;
        text-decoration-color: #a2c198;
        color: #a2c198;
        font-weight: bold;
      }
    }
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 24px;

    .icon {
      margin: 0 8px;
      border-radius: 8px;
      border: 2px solid black;
      width: 72px;
      height: 72px;
      padding: 6px;
      // background-color: #a2c198;
      // color: white;
    }
  }
}