@font-face {
  font-family: NotoSans;
  src: url("./NotoSansCJKjp-Regular.otf");
}

@font-face {
  font-family: BebasNeue;
  src: url("./BebasNeue-Regular.otf");
}

@font-face {
  font-family: GenJyuuGothicL;
  src: url("./GenJyuuGothicL-Monospace-Bold.ttf");
}

@font-face {
  font-family: Rounded;
  src: url("./rounded-x-mgenplus-1cp-regular.ttf");
}

* {
  box-sizing: border-box;
  font-family: NotoSans;
}

body {
  margin: 0;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  background: none;
}

button:hover {
  outline: none;
}

input {
  outline: none;
  border: none;
}
