.product {
  border-radius: 4px;
  width: 160px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 8px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 140px;
    height: 140px;
    object-fit: cover;
  }

  .name {
    font-size: 12px;
    width: 144px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    margin-top: 16px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    margin-top: 8px;
    width: 100%;

    .button {
      text-decoration: underline;
    }
  }
}