.home {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 84px;
  overflow: hidden;
  padding: 12px;

  :global(.slick-dots) {
    li:global(.slick-active) button::before {
      color: #a2c198;
    }
  }

  .slider {
    margin-bottom: 24px;
  }

  .bannerWrapper {
    height: 155px;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    border-radius: 24px;

    .banner {
      margin: 0 auto;

      position: relative;
      width: 100%;
      height: 155px;
      background-position: center;
      background-size: cover;
    }

    button {
      position: absolute;
      bottom: 8px;
      background-color: #f0f0f1;
      color: #6e8566;
      font-size: 12px;
      border-radius: 8px;
      width: 80px;
      box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.2);

      &.add {
        left: 16px;
      }

      &.now {
        left: 50%;
        transform: translateX(-50%);
      }

      &.detail {
        right: 16px;
      }
    }
  }

  .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    justify-items: center;

    .product {
      width: 160px;
      height: 160px;
      // border: 1px solid black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      div {
        border: 1px solid white;
        border-radius: 12px;
        padding: 12px;
        cursor: pointer;
        color: #6e8566;
        background-color: whitesmoke;
        font-weight: 500;
      }
    }
  }

  .more {
    text-align: center;
    color: rgba(grey, 0.5);
    margin-top: 24px;
    letter-spacing: 2px;
  }
}
