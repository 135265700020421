.like {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 20px;

  .likeCount {
    margin-left: 4px;
  }
}
