.wrapper {
  margin-bottom: 128px;
  padding-bottom: 84px;
}

.cover {
  width: 100%;
  height: 192px;
  background: gray;
  z-index: 0;
  border-radius: 0 0 24px 24px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    height: 125px;
  }
}

.info {
  position: relative;
  padding: 0 24px;
  top: -24px;
  max-width: 600px;
  margin: 0 auto;

  .image {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 4px solid white;
  }

  .name {
    color: gray;
    font-weight: bold;
    font-size: 20px;
    padding-left: 12px;
  }

  .email {
    color: gray;
    font-size: 16px;
    padding-left: 12px;
  }

  .buttons {
    position: absolute;
    top: 36px;
    right: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 12px 24px;
  margin: 24px;
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 12px;

  @media screen and (min-width: 652px) {
    margin: 24px auto;
  }

  .number {
    font-weight: bold;
    & + .number {
      margin-left: 48px;
    }
    .value {
      color: #a2c198;
      font-size: 16px;
    }
    .key {
      color: gray;
      font-size: 12px;
    }
  }

  .title {
    color: gray;
    font-size: 16px;
    width: 100%;
  }

  .content {
    color: gray;
    font-size: 12px;
    width: 100%;
  }
}

.followButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.intro {
  width: 300px;
  margin: 12px auto;
  word-break: break-word;
}

.tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .tag {
    width: fit-content;
    text-align: center;
    color: white;
    font-size: 12px;
    padding: 0px 4px 0px 8px;
    opacity: 0.2;
    margin: 6px 12px;
    position: relative;
    cursor: pointer;
    font-family: Rounded;
    font-weight: bold;
    line-height: 22px;
    border-radius: 4px 0 0 4px;

    &.createTag {
      opacity: 0.6;
      background-color: gray;
      border-left-color: gray;

      &::before {
        border-left-color: inherit;
      }
    }

    &.active {
      opacity: 0.6;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -9px;
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 9px solid gray;
      border-left-color: inherit;
    }

    &.public {
      background-color: rgb(255, 101, 101);
      opacity: 0.6;

      &::before {
        border-left: 9px solid rgb(255, 101, 101);
      }

      &.active {
        opacity: 1;
      }
    }
  }
}

.tabs {
  height: 36px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 16px auto 16px;
  border-radius: 18px;
  font-size: 16px;
  background-color: rgba(162, 193, 152, 0.6);
  backdrop-filter: blur(8px);
  z-index: 1;

  button {
    outline: none;
  }
}

.posts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:global(.github-picker) {
  width: fit-content !important;
}
