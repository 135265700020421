.videos {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
  background-color: white;
  overflow: hidden;

  img {
    border-radius: 24px;
    overflow: hidden;
  }
}

.tabs {
  position: fixed;
  top: 60px;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  max-width: 768px;
  z-index: 1;
  transition: top 0.4s;
  color: white;
  background-color: rgba(162, 193, 152, 0.6);
  backdrop-filter: blur(8px);

  button {
    outline: none;
  }
}

.video {
  text-align: center;
  width: 100%;
  margin-top: 36px;
  transition: background-color 1s;
  margin-bottom: 24px;

  :global(.slick-dots) {
    li:global(.slick-active) button::before {
      color: #a2c198;
    }
  }
}

.rank {
  margin-bottom: 12px;
  width: 100%;

  .top {
    color: white;
    padding: 12px;
    background-color: #a2c19894;
    border-radius: 24px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 12px;

    p {
      font-family: GenJyuuGothicL;
      margin: 0 auto 1em;
      width: fit-content;

      background-color: #f0f0f1;
      color: rgb(92, 92, 92);
      border-radius: 12px;
      padding: 0 16px;
    }

    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      .item {
        img {
          width: 64px;
          height: 64px;
          object-fit: cover;
          border-radius: 50%;
          border: 2px solid white;
          background-color: white;
          cursor: pointer;
        }

        .itemName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 64px;
          text-align: center;
          background-color: rgba(255, 255, 255, 0.5);
          color: green;
          border-radius: 12px;
          position: relative;
          top: -12px;
          font-size: 12px;
          padding: 0 4px;
          opacity: 0.8;
          backdrop-filter: blur(8px);
          border: 2px solid white;
        }
      }
    }
  }
}

.posts {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.titleWrapper {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  .title {
    padding: 4px 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    font-size: 20px;
    border-radius: 8px;
    margin: 0 8px;
    border: 2px solid #a2c198;
  }
}
