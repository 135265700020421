.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 12px;
  z-index: 1;

  .menu {
    width: 60px;
    position: absolute;
    top: 16px;
    left: 12px;
  }

  .search {
    width: 60px;
    position: absolute;
    top: 16px;
    left: 50px;
  }

  input.searchInput {
    width: 210px;
    position: absolute;
    top: 16px;
    left: 90px;
    border-bottom: 1px solid #a2c198;
    color: #2f4f4f;
    background: none;

    &.inverted {
      border-color: white;
      color: white;

      &::placeholder {
        color: white;
      }
    }

    &::placeholder {
      color: #c0c0c0;
    }
  }

  .cancel {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  :global(.el-icon-menu) {
    font-size: 24px;
    cursor: pointer;
  }

  .go {
    border: 10px solid #a2c198;
    background-color: #a2c198;
    color: white;
    border-radius: 24px;
    font-size: 12px;

    @media screen and (max-width: 374px) {
      font-size: 10px;
    }
  }

  img.logo {
    height: 48px;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 374px) {
      height: 42px;
      top: 10px;
    }
  }

  img.login {
    width: 100px;
    cursor: pointer;
  }

  .userImage {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: block;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 12px;
    right: 12px;

    .count {
      background-color: red;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 12px;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      border-radius: 50%;
      color: white;
    }
  }
}

.footer {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  position: fixed;
  bottom: 0;
  max-width: 768px;
  z-index: 2;
  width: 100%;

  .tabs {
    width: 100%;
    color: white;
    max-width: 768px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 40px;

    .tab {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      position: relative;

      &.active {
        border-bottom: 2px solid #2e8b57;
      }

      .hint {
        color: white;
        position: absolute;
        right: -4px;
        bottom: 4px;
        background-color: #ff6118;
        font-size: 12px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        padding-left: 1px;
      }
    }
  }

  .company {
    width: 100%;
    color: white;
    font-size: 10px;
    margin: 0 auto;
    padding-right: 10px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4px;
    height: 25px;

    .center {
      margin-left: 12px;
      font-size: 14px;
    }
  }
}

.searchResult {
  position: fixed;
  top: 60px;
  height: calc(100vh - 60px);
  width: 100vw;
  background-color: white;
  overflow: scroll;
  z-index: 99;
  max-width: 768px;

  .row {
    margin: 0 24px;
    padding: 16px 0;
    & + .row {
      border-top: 1px solid #a2c198;
    }
  }
}

.camera {
  position: fixed;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  background-color: #a2c198;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menuWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.leftMenu {
  position: fixed;
  top: 0;
  height: 100vh;
  left: -240px;
  width: 240px;
  transition: left 0.4s;
  z-index: 99;

  &.open {
    left: 0;
  }
}

.rightMenu {
  position: fixed;
  top: 0;
  height: 100vh;
  right: -240px;
  width: 240px;
  transition: right 0.4s;
  z-index: 99;

  &.open {
    right: 0;
  }

  .unreadCount {
    background-color: red;
    font-size: 12px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    color: white;
    display: inline-block;
    vertical-align: 1px;
  }
}

.wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  max-width: 768px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
  }
}
