
.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: white;
  z-index: 99;
  left: 0;

  .logo {
    width: 144px;
    margin-bottom: 10px;
  }

  .modal {
    width: 80%;
    background-color: white;
    border: 4px solid rgb(162, 193, 152);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 24px;

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .icons {
      margin: 12px auto;
      display: flex;
    }

    button {
      border: 1px solid black;
    }
  }
}