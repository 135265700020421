.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: white;
  z-index: 99;

  .buttonClose {
    position: fixed;
    right: 16px;
    top: 16px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      border-bottom: 1px solid grey;
      margin-bottom: 24px;
    }

    button {
      width: 180px;
      height: 36px;
      border-radius: 18px;
      

      img {
        height: 24px;
        margin-right: 8px;
      }
    }

    .login {
      background-color: #a2c198;
      color: white;
      border: 2px solid #a2c198;
    }

    .hint {
      font-size: 12px;
      color: #a2c198;
      margin-top: 4px;

      a {
        text-decoration: underline;
      }
    }

    .line {
      border-top: 1px solid #a2c198;
      width: 80px;
      margin: 12px 0 30px;
    }

    .googleLogin {
      border: 1px solid grey;
      color: grey;
      margin-top: 24px;
    }

    .facebookLogin {
      border: 1px solid grey;
      color: grey;margin-bottom: 16px;
      margin-top: 16px;
    }
  }

  .loginModal {
    width: 80%;
    background-color: white;
    border: 4px solid rgb(162, 193, 152);
    padding: 48px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 240px;
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      height: 48px;
      font-size: 16px;
      border-radius: 16px;
    }
    .back {
      text-align: center;
      width: 100%;
    }
.wall {
  width: 240px;
  height: 2px;
  background-color: rgb(162, 193, 152);
  margin-top: 40px;
}
    .form {
      width: 240px;
      margin-bottom: 24px;

      input {
        border: 1px solid rgb(162, 193, 152);
        border-radius: 8px;
        line-height: 16px;
        font-size: 16px;
        outline: none;
        padding: 4px;
        width: 240px;
        height: 48px;
      }
    }

    .message {
      margin-bottom: 24px;
      font-size: 12px;
      color: red;
      font-weight: bold;
    }

    a {
      margin-top: 24px;
      text-decoration: underline;
    }
  }
}