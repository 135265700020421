.button {
    width: 240px;
    height: 50px;
    background-color: black;
    color: whitesmoke;
    font-size: 16px;
    border-radius: 11px;
    margin-left: auto;
    margin-right: auto;
}
.center {
    display: block;
    margin-top: 24px;
    font-weight: bold;

    svg {
        font-size: 18px;
    }
}