.newPost {
  position: fixed;
  top: 0;
  padding: 12px;
  background: white;
  left: 0;
  right: 0;
  bottom: 18px;
  z-index: 99;
  overflow-y: scroll;

  :global(.slick-slider) {
    margin-bottom: 36px;
  }
}

.image {
  height: 192px;
  border-radius: 8px;
  margin: 12px auto;
  display: block;
  object-fit: cover;
  border: 1px solid gray;
}

.loading {
  height: 192px;
  border-radius: 8px;
  margin: 12px auto;
  border: 1px solid gray;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: block;
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
  border: 1px solid gray;
  outline: none;
  border-radius: 8px;
}

.textArea {
  display: block;
  width: 100%;
  height: 160px;
  margin-bottom: 12px;
  border: 1px solid gray;
  outline: none;
  border-radius: 8px;
}

.publish {
  float: right;
  color: white;
  background-color: #a2c198;
  border-radius: 8px;
  padding: 4px 12px;
  margin: 4px 8px 0;
}

.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: white;
  z-index: 99;
  left: 0;

  .modal {
    width: 92%;
    background-color: white;
    border: 4px solid orange;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 24px;

    .title {
      margin-top: 12px;
      font-size: 20px;
    }

    .logos {
      display: flex;
      margin-top: 20px;

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 12px;

        img {
          width: 80px;
        }
      }
    }

    .description {
      margin-top: 36px;
      font-size: 12px;
      background-color: #a2c198;
      color: white;
      border-radius: 30px;
      padding: 8px 24px;
    }
  }
}
