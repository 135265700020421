.productWrapper {
  .product {
    border: 1px solid lightgray;
    border-radius: 12px;
    width: 140px;
    height: 230px;
    margin: 16px 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
  
    div {
      font-size: 12px;
    }
  
    .delete {
      position: absolute;
      top: -12px;
      right: -16px;
    }
  
    button {
      text-decoration: underline;

      &.verify {
        background-color: #a2c198;
        color: white;
        border-radius: 16px;
        padding: 2px 12px;
        text-decoration: none;

        &:disabled {
          background-color: lightgray;
        }
      }
    }
  }

  .topicWrapper {
    display: flex;
    width: 140px;
    margin: 8px 16px;
    align-items: center;
    justify-content: space-between;

    .topic {
      background: #a2c198;
      color: white;
      padding: 4px 12px;
      border-radius: 16px;
      font-size: 13px;
      cursor: pointer;
    }

    .noTopic {
      padding: 4px 12px;
      border-radius: 16px;
      border: 1px solid black;
      font-size: 13px;
      cursor: pointer;
    }
  }
}
