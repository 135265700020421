.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99;
  max-width: 768px;

  .modal {
    width: 80%;
    background-color: white;
    border: 4px solid rgb(162, 193, 152);
    padding: 16px;
    text-align: center;
    border-radius: 24px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    input {
      border: 1px solid black;
    }

    .buttonsWrapper {
      display: flex;
      height: 76px;
      justify-content: space-around;

      .topicFree {
        font-size: 16px;
        padding: 4px 16px;
        border-radius: 22px;
        margin-top: 36px;
        border: 1px solid black;
      }
    }
  }
}
