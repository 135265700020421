.brand {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 84px;
  position: relative;

  .buttons {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 60px;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: rgb(162, 193, 152);
    cursor: pointer;
  }

  .cover {
    height: 192px;
    border: 1px solid lightgray;
    border-radius: 0 0 24px 24px;
    width: 100%;
    position: absolute;
    background-color: rgb(255, 248, 240);
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;
  }

  .info {
    margin: 108px 0 16px;
    width: 100%;
    padding: 0 24px;
    display: flex;
    z-index: 0;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 160px;

      .placeholder {
        background-color: white;
        border: 2px solid rgb(162, 193, 152);
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 128px;
          height: 128px;
          object-fit: cover;
          border: 8px solid white;
          border-radius: 50%;
        }
      }

      .links {
        margin-top: 8px;
        display: flex;

        img {
          width: 32px;
          margin: 0 8px;
        }
      }

      .name {
        font-size: 24px;
      }
    }

    .right {
      margin-left: 8px;
      overflow: hidden;

      .tags {
        margin-top: 12px;
        padding: 2px 0;
        display: flex;
        flex-wrap: wrap;
        min-height: 38px;

        .tag {
          border: 1px solid gray;
          border-radius: 13px;
          padding: 0 12px;
          margin: 4px;
          background-color: white;
          word-break: keep-all;
        }
      }

      .points {
        text-align: center;
        margin: 48px 0 12px;
        font-size: 24px;
        color: black;
        font-family: Rounded;

        img {
          width: 36px;
          vertical-align: text-bottom;
        }

        span {
          font-size: 48px;
          line-height: 48px;
          font-family: Rounded;
        }

        .fill {
          margin: 0 auto;
          background-color: #a2c198;
          border-radius: 18px;
          color: white;
          display: block;
          font-size: 16px;
          margin-top: 8px;
          height: 36px;
          padding: 0 24px;
        }
      }
    }
  }

  .description {
    margin-top: 16px;
    margin-bottom: 24px;
    width: 100%;
    padding: 0 24px;

    ol {
      padding: 0 18px;
    }
  }

  .products {
    width: 98%;
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    justify-items: center;

    .newProduct {
      border-radius: 4px;
      height: 200px;
      width: 160px;
      margin: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 8px;
      overflow: hidden;
      cursor: pointer;

      .icon {
        height: 140px;
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 44px;
        color: rgb(170, 170, 170);
      }

      .text {
        font-size: 12px;
        margin-top: 16px;
      }
    }
  }

  .videos {
    width: 100%;
    border-top: 4px solid #a2c198;
    margin-top: 60px;
    padding-bottom: 24px;

    .subtitle {
      color: #a2c198;
      font-size: 24px;
      padding: 24px;
      font-weight: bold;
    }

    .posts {
      display: flex;
      justify-content: space-around;
    }
  }
}
