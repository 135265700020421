.wrapper {
  margin-bottom: 72px;
  padding: 12px;

  table {
    width: 100%;

    tr {
      cursor: pointer;

      &:hover {
        background-color: lightgray;
      }

      td:first-child {
        width: 60px;
      }
      td:nth-child(2) {
        max-width: 0;
        overflow: hidden;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:nth-child(2) {
            font-size: 12px;
          }
        }
      }
      td:nth-child(3) {
        width: 96px;
      }
    }

    img {
      border-radius: 50%;
      width: 48px;
      height: 48px;
    }
  }
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;

  .modal {
    position: absolute;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    width: 80%;
    padding: 32px;
    background-color: white;
    box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    max-width: 576px;

    img {
      width: 100%;
    }

    div:nth-child(2) {
      font-size: 20px;
      margin-top: 4px;
    }

    div:nth-child(3) {
      font-size: 16px;
    }
  }
}
