.wrapper {
  padding: 24px 24px 64px;

  h2 {
    text-align: center;
    font-family: GenJyuuGothicL;
  }

  .plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;

    .plan {
      width: 240px;
      border: 4px solid #a2c198;
      margin: 0 24px 24px;
      padding: 12px;
      position: relative;
      height: 420px;

      .title {
        font-family: GenJyuuGothicL;
        text-align: center;
        display: flex;
        align-items: center;
        font-size: 24px;
        height: 64px;
        line-height: 32px;
        justify-content: center;
      }

      button {
        color: white;
        background-color: #a2c198;
        margin: 12px 0;
        padding: 4px 12px;
        border-radius: 12px;
      }

      ul {
        padding-inline-start: 12px;

        li {
          font-size: 12px;
          letter-spacing: 1px;
          line-height: 24px;
        }
      }

      .topLeftRing {
        position: absolute;
        width: 48px;
        height: 24px; /* as the half of the width */
        border-top-left-radius: 28px; /* 100px of height + 10px of border */
        border-top-right-radius: 28px; /* 100px of height + 10px of border */
        border: 4px solid orange;
        border-bottom: 0;
        left: 4px;
        top: 4px;
      }
      .topRightRing {
        position: absolute;
        width: 48px;
        height: 24px; /* as the half of the width */
        border-top-left-radius: 28px; /* 100px of height + 10px of border */
        border-top-right-radius: 28px; /* 100px of height + 10px of border */
        border: 4px solid orange;
        border-bottom: 0;
        right: 4px;
        top: 4px;
      }
      .bottomLeftRing {
        position: absolute;
        width: 48px;
        height: 24px; /* as the half of the width */
        border-bottom-left-radius: 28px; /* 100px of height + 10px of border */
        border-bottom-right-radius: 28px; /* 100px of height + 10px of border */
        border: 4px solid orange;
        border-top: 0;
        left: 4px;
        bottom: 4px;
      }
      .bottomRightRing {
        position: absolute;
        width: 48px;
        height: 24px; /* as the half of the width */
        border-bottom-left-radius: 28px; /* 100px of height + 10px of border */
        border-bottom-right-radius: 28px; /* 100px of height + 10px of border */
        border: 4px solid orange;
        border-top: 0;
        right: 4px;
        bottom: 4px;
      }
      .whiteMask {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 24px;
        right: 24px;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 16px;
      }
    }
  }

  .points {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    h3 {
      font-size: 20px;
    }

    input {
      border: 1px solid black;
      border-radius: 8px;
      font-size: 32px;
      text-align: center;
    }

    .line {
      font-size: 32px;
    }

    .fee {
      font-size: 32px;
      text-align: center;
    }

    button {
      color: #a2c198;
      text-decoration: underline;
      font-size: 32px;
      margin-top: 16px;
    }
  }
}
