.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99;
  left: 0;

  .progressBarWrapper {
    height: 1em;
    width: 80%;
  }
}
