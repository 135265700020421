.wrapper {
  height: 100vh;
  width: 100vw;
  max-width: 768px;

  :global(.slick-slider) {
    overflow: hidden;
  }
  
  :global(.slick-dots) {
    bottom: 0px;

    li button::before {
      color: rgb(162, 193, 152);
      opacity: 1;
      
    }

    li:global(.slick-active) button::before {
      color: #2E8B57;
    }
  }
}

.video {
  text-align: center;
  width: 100%;
  height: 533px;
  background: black;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: contain;

  iframe, video {
    height: 100%;
    width: 100%;
  }
}

.title {
  padding: 8px 8px 0;
  font-weight: bold;
  font-size: 16px;
  margin-top: 30px;
}

.value {
  padding: 0 8px;
  font-weight: bold;
  font-size: 12px;
  word-break: break-all;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 8px;

  .user {
    display: flex;
    align-items: center;

    .userPhoto {
      width: 20px;
      height: 20px;
      background-size: contain;
      margin: 0 auto;
      border: 1px solid rgb(162, 193, 152);
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}

.moreInfo {
  display: flex;
  align-items: center;
  padding: 8px;

  .brand,
  .product {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin: 0 8px;
  }

  a {
    background: seagreen;
    margin: 0 8px;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 12px;
  }
}

.forum {
  border-top: 1px solid #a2c198;
  padding: 8px 0 78px;
  margin: 0 8px;

  .inputWrapper {
    display: flex;
    padding: 8px;

    input {
      flex-grow: 1;
      border-bottom: 1px solid rgba(gray, 0.3);
    }

    button {
      background-color: #e7417a;
      color: white;
      padding: 4px 8px;
      border-radius: 8px;
      margin-left: 8px;
    }
  }

  .comment {
    display: flex;
    padding: 8px;
    width: 100%;

    .userImage {
      width: 32px;
      height: 32px;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      margin-right: 8px;
      flex-shrink: 0;
    }

    .commentInfo {
      flex-grow: 1;

      .userName {
        font-size: 12px;
        font-weight: bold;

        span {
          font-weight: normal;
          color: gray;
          margin-left: 4px;
        }

        img {
          vertical-align: -2px;
        }
      }

      p {
        font-size: 14px;
        word-wrap: break-word;
        word-break: break-all;
      }

      input {
        font-size: 14px;
        border-bottom: 1px solid rgba(gray, 0.3);
        width: 100%;
      }

      button {
        font-size: 14px;
        color: gray;
        float: right;
      }
    }

    .floor {
      font-size: 12px;
      color: gray;
    }

    .contextButton {
      width: 24px;
      display: flex;
      justify-content: center;
      margin-left: 4px;
      padding-top: 2px;
    }
  }
}

:global(.tooltip) {
  :global(.arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    background: white;
    box-shadow: 2px 2px 10px 1px gray;
    color: gray;

    span {
      padding: 0 8px;

      + span {
        border-left: 1px solid gray;
      }
    }
  }
}

.tooltip {
  text-align: left;

  .openLink {
    padding: 8px 0;
    border-bottom: 1px solid gray;
    img {
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }
  
  .share {
    padding: 8px 0;
    img {
      margin-right: 4px;
      margin-left: 4px;
    }
  }
}
