.post {
  width: 320px;
  position: relative;
  letter-spacing: 0.6px;
  margin-bottom: 36px;

  .postBody {
    border-radius: 12px;
    border: 2px solid #a2c198;
    overflow: hidden;
    padding-bottom: 12px;

    .postBrand {
      padding: 4px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      img {
        vertical-align: top;
      }
    }
  }

  iframe,
  video {
    width: 100%;
    height: 192px;
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  img.postImage {
    width: 100%;
  }

  .multiple {
    position: absolute;
    top: 44px;
    right: 10px;
  }

  .postTitle {
    padding: 0 8px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    margin: 4px 0 8px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .arrow {
      position: absolute;
      right: 4px;
      bottom: -3px;
      width: 20px;
    }
  }

  .postContent {
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    max-height: 53px;
  }

  .postTag {
    width: fit-content;
    text-align: center;
    color: white;
    font-size: 12px;
    padding: 0px 4px 0px 8px;
    opacity: 0.6;
    margin-left: 8px;
    margin-top: 8px;
    position: relative;
    display: inline-block;
    font-family: Rounded;
    font-weight: bold;
    line-height: 22px;
    border-radius: 4px 0 0 4px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -9px;
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 9px solid green;
      border-left-color: inherit;
    }
  }

  .postPublic {
    width: fit-content;
    text-align: center;
    background-color: rgb(255, 101, 101);
    color: white;
    font-size: 12px;
    padding: 0px 4px 0px 4px;
    margin-left: 16px;
    margin-top: 8px;
    display: inline-block;
    border-radius: 4px;
    line-height: 23px;
  }

  .postCreated {
    position: absolute;
    bottom: 12px;
    left: 4px;
    color: rgba(128, 128, 128, 0.5);
    background-color: white;
    padding: 0 8px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;

    .arrow {
      margin-right: 8px;
    }

    .user {
      display: flex;
      align-items: center;

      .userPhoto {
        width: 20px;
        height: 20px;
        background-size: contain;
        margin: 0 auto;
        border: 1px solid rgb(162, 193, 152);
        border-radius: 50%;
        margin-right: 8px;
      }

      .userName {
      }
    }

    .view {
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-right: 12px;

      .viewCount {
        margin-left: 4px;
        color: rgba(128, 128, 128, 0.5);
        font-size: 16px;
      }
    }

    .contextButton {
      margin-right: 6px;
    }
  }

  .delete {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0;
    background: white;
    width: 24px;
  }
}

.tooltip {
  text-align: left;

  .openLink {
    padding: 8px 0;
    border-bottom: 1px solid gray;
    img {
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }

  .share {
    padding: 8px 0;
    img {
      margin-right: 4px;
      margin-left: 4px;
    }
  }
}

.comment {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 20px;

  .commentCount {
    margin-left: 4px;
  }
}
