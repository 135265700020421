.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99;
  max-width: 768px;

  .modal {
    width: 80%;
    background-color: white;
    border: 4px solid rgb(162, 193, 152);
    padding: 16px;
    text-align: center;
    border-radius: 24px;
    height: 512px;

    .title {
      font-size: 32px;
      font-weight: bold;
    }

    .sliderWrapper {
      margin-top: 16px;

      .item {
        border: 6px solid orange;
        border-radius: 32px;
        padding: 20px;
        height: 320px;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .topicTitle {
          font-size: 24px;
          margin-bottom: 12px;
        }

        img {
          height: 100px;
          margin: 0 auto;
        }

        .topicText {
          font-size: 12px;
          margin-top: 12px;
        }

        .topicStart {
          font-size: 24px;
          background: orange;
          color: white;
          padding: 4px 54px;
          border-radius: 22px;
          margin-top: 12px;
        }
      }

      :global(.slick-dots) li {
        margin: 0;
      }
    }

    .dots {
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: center;

      .dot {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 20px;
          height: 20px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;

          &::before {
            font-family: 'slick';
            font-size: 6px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: '•';
            text-align: center;
            opacity: 0.25;
            color: black;
          }
        }
      }
    }

    .buttonsWrapper {
      display: flex;
      height: 76px;
      justify-content: space-around;

      .topicFree {
        font-size: 16px;
        padding: 4px 16px;
        border-radius: 22px;
        margin-top: 36px;
        border: 1px solid black;
      }
    }
  }
}
