.me {
  padding-bottom: 120px;
}

.image {
  width: 64px;
  height: 64px;
  background-size: contain;
  margin: 0 auto;
  border: 1px solid rgb(162, 193, 152);
  border-radius: 50%;
  margin-top: 32px;
}

.title {
  text-align: center;
  margin: 24px;
  font-size: 24px;
}

.tabs {
  height: 36px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 16px auto 16px;
  border-radius: 18px;
  font-size: 16px;
  background-color: rgba(162, 193, 152, 0.6);
  backdrop-filter: blur(8px);
  z-index: 1;

  button {
    outline: none;
  }
}

.value {
  text-align: center;
  margin: 12px;
  font-size: 42px;
  color: #da291c;
  font-family: BebasNeue;
  font-weight: bold;
}

.link {
  margin: 0 auto;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;

  span {
    border-bottom: 1px solid black;
  }
}

.posts {
  display: flex;
  justify-content: space-around;
}

.products {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  justify-items: center;
}

.records {
  margin: 42px auto 0;
  width: 360px;

  .record {
    display: flex;
    justify-content: center;
    padding: 4px;

    &:first-child {
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
    }

    div {
      text-align: center;
      width: 33%;
    }
  }
}
