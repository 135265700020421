.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 640px;
  justify-content: space-around;
  padding: 24px 0 144px;

  .sliderWrapper {
    width: 100%;
    height: 150px;
    text-align: center;

    img {
      height: 150px;
      width: auto !important;
    }
  }

  .hint {
    padding: 24px;
    font-size: 20px;
    color: rgb(162, 193, 152);
    font-weight: bold;
  }

  .title {
    padding: 24px;
    font-size: 24px;
    font-weight: bold;
  }

  .description {
    padding: 24px;
    font-size: 20px;
    line-height: 32px;
  }

  button {
    background-color: rgb(46, 139, 87);
    color: white;
    padding: 0 24px;
    font-size: 16px;
    border-radius: 18px;
    height: 36px;
  }
}