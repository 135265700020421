.button {
    width: 240px;
    height: 50px;
    background-color: black;
    color: whitesmoke;
    font-size: 16px;
    border-radius: 11px;
    margin-left: auto;
    margin-right: auto;
}
.wall {
    width: 300px;
    height: 1px;
    background-color: #a2c198;
    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
}
.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}